import React, { useState, useEffect } from "react";
import { db, auth, logOut } from "../firebase.js";
import { Link, useNavigate } from "react-router-dom";

import {
  collection,
  doc,
  getDocs,
  addDoc,
  getDoc,
  Timestamp,
  query,
  where,
  updateDoc,
  onSnapshot,
  orderBy,
} from "firebase/firestore";

import styles from "./Dashboard.module.css";
import { CSVLink } from "react-csv";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import getClosestRegional from "./getClosestRegional.js";
import { AppRegistration } from "@mui/icons-material";

function Leaderboard() {
  const appsRef = collection(db, "apps");
  const authRef = collection(db, "user-emails");
  const ratingsRef = collection(db, "ratings");

  let [judges, setJudges] = useState([]);
  let [judgeScores, setJudgeScores] = useState({});
  let [companies, setCompanies] = useState({});
  let [ratings, setRatings] = useState([]);
  let [sortBy, setSortBy] = useState({
    sortKey: "startup_name",
    reversed: false,
  });

  const getCompanies = async () => {
    let snapshot = await getDocs(
      // query(appsRef, where("last_access", "==", null))
      query(appsRef, where("score", "==", 1))
    );

    let temp = {};
    snapshot.docs.forEach((doc) => {
      temp[doc.id] = {
        ...doc.data(),
        raw_total_score: 0,
        total_score: 0,
        score_count: 0,
        avg_raw_score: 0,
        avg_score: 0,
        judge_comments: [],
      };
    });
    setCompanies(temp);
    getRatings();
  };

  const getRatings = async () => {
    let snapshot = await getDocs(ratingsRef);

    setRatings(
      snapshot.docs.map((doc) => {
        return {
          ...doc.data(),
        };
      })
    );
  };
  // console.log("ADIT");
  // console.log(
  //   JSON.stringify(
  //     ratings
  //       .filter((rating) => rating.judge == "aditnbhansali@gmail.com")
  //       .map((rating) => {
  //         return {
  //           ...rating,
  //           application: companies[rating.application].startup_name,
  //         };
  //       })
  //   )
  // );

  const getJudges = async () => {
    let snapshot = await getDocs(
      // query(appsRef, where("last_access", "==", null))
      query(authRef, where("judge", "==", true))
    );

    setJudges(
      snapshot.docs.map((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      })
    );
  };

  useEffect(() => {
    // getCompanies();
    getJudges();
    getCompanies();
  }, []);

  useEffect(() => {
    let scores = {};
    ratings.forEach((rating) => {
      if (!(rating.judge in scores)) {
        scores[rating.judge] = { data: [], mean: 0, sd: 0 };
      }
      scores[rating.judge].data.push(rating.rawscore);
    });
    for (const judge in scores) {
      let data = scores[judge].data;
      let mean = data.reduce((a, b) => a + b) / data.length;
      scores[judge].mean = mean;
      scores[judge].sd = Math.sqrt(
        data.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) /
          data.length
      );
    }
    setCompanies((currentCompanies) => {
      console.log("COMPANIES");
      console.log(currentCompanies);
      ratings.forEach((rating) => {
        if (rating.application in currentCompanies) {
          let z = 0;
          if (scores[rating.judge].sd != 0) {
            z =
              (rating.rawscore - scores[rating.judge].mean) /
              scores[rating.judge].sd;
          }
          currentCompanies[rating.application].total_score += z;
          currentCompanies[rating.application].raw_total_score +=
            rating.rawscore;
          currentCompanies[rating.application].score_count++;
          currentCompanies[rating.application].avg_raw_score =
            currentCompanies[rating.application].raw_total_score /
            currentCompanies[rating.application].score_count;
          currentCompanies[rating.application].avg_score =
            currentCompanies[rating.application].total_score /
            currentCompanies[rating.application].score_count;

          // comments
          if (rating.comments != "") {
            currentCompanies[rating.application].judge_comments.push(
              rating.comments
            );
          }
        }
      });
      return { ...currentCompanies };
    });
    console.log("this thing ran");
  }, [ratings]);

  document.body.style.backgroundColor = "whitesmoke";

  const truncate = (str) => {
    if (typeof str === "string") {
      if (str.length > 50) {
        return str.substring(0, 50) + "...";
      }
    }
    return str;
  };

  const sortDict = (dict, keyName, reversed) => {
    let sorted = dict.sort((a, b) => {
      let type = typeof a[keyName];
      if (a === undefined && b === undefined) {
        return 0;
      }
      if (a === undefined) {
        return -1;
      }
      if (b === undefined) {
        return 1;
      }
      if (type === "string") {
        return a[keyName].localeCompare(b[keyName]);
      } else if (type === "number") {
        return a[keyName] - b[keyName];
      }
    });
    if (reversed) {
      return sorted.reverse();
    }
    return sorted;
  };

  console.log("JUDGES");
  console.log(judges);

  const headers = ["email", "judged", "toJudge", "id"];
  const companyHeaders = [
    "startup_name",
    "avg_score",
    "avg_raw_score",
    "score_count",
  ];
  const regionals = [
    "Paris, France",
    "Singapore",
    "Houston, TX, USA",
    "New York, NY, USA",
    "Berkeley, CA, USA",
  ];

  const csv_headers = [
    { label: "Startup Name", key: "startup_name" },
    { label: "Region", key: "region" },
    { label: "Closest Airport", key: "airport" },
    { label: "Closest Regional", key: "closest_regional" },
    { label: "Primary Contact", key: "applier_name" },
    { label: "Email", key: "applier_email" },
    { label: "Phone", key: "applier_phone" },
    { label: "Judge Comments", key: "judge_comments" },
  ];

  return (
    <div className={styles.container}>
      <center>
        <h1>Judging Leaderboard</h1>
        <h3>Companies</h3>

        <CSVLink
          data={Object.values(companies)}
          headers={csv_headers}
          filename={"TL_Leaderboard.csv"}
        >
          Download CSV (All Regions)
        </CSVLink>

        <br />
        <br />
        {regionals.map((regional) => {
          return (
            <>
              <h2>{regional}</h2>
              <table
                className="table table-striped table-hover"
                style={{ maxHeight: "80vh", display: "table" }}
              >
                <thead>
                  <tr>
                    {companyHeaders.map((header, index) => {
                      return <th key={index}>{header}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {sortDict(
                    Object.values(companies).filter(
                      (company) => company.closest_regional == regional
                    ),
                    "avg_score",
                    true
                  ).map((company, index) => {
                    return (
                      <tr key={index}>
                        {companyHeaders.map((header) => {
                          return <th>{company[header]}</th>;
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          );
        })}

        <br />
        <h2>Judge Status</h2>
        <table
          className="table table-striped table-hover"
          style={{ maxHeight: "80vh", display: "table" }}
        >
          <thead>
            <tr>
              {headers.map((header, index) => {
                return <th key={index}>{header}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {/* {sortDict(
              applicationsWithScores,
              sortBy.sortKey,
              sortBy.reversed
            ).map((application, index) => {
              return (
                <tr key={index} id={index}>
                  <td key={"startup_name"}>
                    {truncate(application["startup_name"])}
                  </td>
                  <td key={"raw_score"}>
                    {truncate(application["raw_score"])}
                  </td>
                </tr>
              );
            })} */}
            {judges.map((judge, index) => {
              return (
                <tr key={index}>
                  {headers.map((header, index) => {
                    return <th key={index}>{judge[header]}</th>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <br />
      </center>
    </div>
  );
}

export default Leaderboard;
